import React from 'react';

import Layout from '../../components/Layout';
import CourseFeatures from '../../components/CourseFeatures';
import pic1 from '../../assets/images/courses/BusinessAnalyst-Training.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const BusinessAnalystTrng = () => (
  <Layout>
    <div id="main" >
      <div className="inner">
        <article className="box"> 
        <h1>Business Analyst Training</h1>
        <span className="image main">
          <img src={pic1} alt="" />
        </span>
        </article>
        <article className="box">
        
        
        <Container align="justify">
          <div className="inner">
          <p >
            This <b>Business Analyst</b> Training Program is a boot camp-style course that will help you to gain 
            the foundational skills to perform the role of a business analyst (BA) in both waterfall and agile environments.
            You will learn how to plan business analysis activities; as well as the basics of eliciting, analyzing, modeling, and writing requirements.
            This course is aligned to the requirements management and business analysis principles outlined by 
            the International Institute of Business Analysis (IIBA®) and Project Management Institute (PMI)®.

            
          </p>
          <p>PMI is a registered mark of the Project Management Institute, Inc.</p>
          </div>
          <article>
            <p>
              With a balanced mix of theoretical concepts and hands-on exercises, comibined with a capstone project that is as
              close to the real things as it gets, we promise to equip you with the necessary skills that will enable you to perform
              as a business analyst.This is an intensive 12 Week  program followed by an opportunity for a 10 – week co-op program.
            </p><br />
          </article>
        </Container>
        <Container align="justify">
          <Row>
            <Col>What this course offers
              <ListGroup>
                    <ListGroup.Item><span className="icon fa-check-square-o">  Theoretical knowledge about the role and importance of the BA in an IT project.</span></ListGroup.Item> 
                    <ListGroup.Item><span className="icon fa-check-square-o">  Vocabulary standards and business analysis practices through the use of the IIBA A Guide to the Business Analysis Body of Knowledge (BABOK Guide)</span></ListGroup.Item> 
                    <ListGroup.Item><span className="icon fa-check-square-o">  How to gather requirements from stakeholders, with an emphasis on interviews</span></ListGroup.Item> 
                    <ListGroup.Item><span className="icon fa-check-square-o">  What are the elements of requirements management and communication and the BA's role in them</span></ListGroup.Item> 
                    <ListGroup.Item><span className="icon fa-check-square-o">  How to document requirements for different types of projects</span></ListGroup.Item>  
                    <ListGroup.Item><span className="icon fa-check-square-o">  What are the necessary competencies and tools expected from a business analyst.</span></ListGroup.Item>  
                    <ListGroup.Item><span className="icon fa-check-square-o">  Knowledge about waterfall, incremental, and agile lifecycles and how they change BA practices </span></ListGroup.Item> 
              </ListGroup>
            </Col>
          </Row><br />
          <Row>
            <Col>Who this course is for 
              <ListGroup>
                    <ListGroup.Item><span className="icon fa-check-square-o">  This course is for students fresh out of college looking for new jobs as well as experienced business analysts. </span></ListGroup.Item> 
                    <ListGroup.Item><span className="icon fa-check-square-o">  Anyone who wants to expand their skillset or become a business analyst. </span></ListGroup.Item> 
                    <ListGroup.Item><span className="icon fa-check-square-o">  Business analysts who are not feeling confident about their existing  skills</span></ListGroup.Item> 
                    <ListGroup.Item><span className="icon fa-check-square-o">  Anyone who wants to make a career change. </span></ListGroup.Item> 
              </ListGroup>
            </Col>
          </Row>
        </Container><br />
        <CourseFeatures /><br />
        

        <Accordion defaultActiveKey="0">
          <Card bg="info" text="black">
            <Card.Header as="h5">COURSE OUTLINE</Card.Header>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              <span className="icon major style1 h5 fa-plus-circle" >    1.	Welcome to Course (Week -1)</span>         
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
              <ListGroup>
                <ListGroup.Item>1.1	Software Development Lifecycles – Agile, Waterfall and Hybrid</ListGroup.Item>
                <ListGroup.Item>1.2	SDLC Phases – Importance</ListGroup.Item>
                <ListGroup.Item>1.3	IT Project Overview  </ListGroup.Item>
                <ListGroup.Item>1.4	Team Structure - Responsibilities  </ListGroup.Item>
                <ListGroup.Item>1.5	Business Analyst Role – Salary, Career Path, Responsibilities </ListGroup.Item>
              </ListGroup>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="1">
              <span className="icon major style1 h5 fa-plus-circle" >    2.	CBAP/CCBA Certification: The Credentials (Week 1)</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
              <ListGroup>
                <ListGroup.Item>2.1	The International Institute of Business Analysis (IIBA)</ListGroup.Item>
                <ListGroup.Item>2.2	The CBAP Certification</ListGroup.Item>
                <ListGroup.Item>2.3	The CCBA Certification</ListGroup.Item>
                <ListGroup.Item>2.4	The Certification Application Processes</ListGroup.Item>
                <ListGroup.Item>2.5	The Examination Processes</ListGroup.Item>
                <ListGroup.Item>2.6	The Professional Code of Conduct</ListGroup.Item>
                <ListGroup.Item>2.7	The Examination Structures</ListGroup.Item>
                <ListGroup.Item>2.8	Other BA Certifications</ListGroup.Item>
              </ListGroup>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="2">
              <span className="icon major style1 h5 fa-plus-circle" >    3.	Business Analysis and Key Concepts Overview (Week 2)</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="2">
              <Card.Body>
              <ListGroup>
                <ListGroup.Item>3.1	What is Business Analysis?</ListGroup.Item>
                <ListGroup.Item>3.2	The Role and Competencies of the Business Analyst</ListGroup.Item>
                <ListGroup.Item>1.1	The Systems/Software Development Life Cycle (SDLC)</ListGroup.Item>
                <ListGroup.Item>3.4	Project and Requirements Life Cycle Management</ListGroup.Item>
                <ListGroup.Item>3.5	Agile Principles
                  <ListGroup>
                    <ListGroup.Item>3.5.1	Project Life cycle in Agile</ListGroup.Item>
                    <ListGroup.Item>3.5.2	Characteristics of an AGILE Business Analyst</ListGroup.Item>
                    <ListGroup.Item>3.5.3	Key BA Skills for Agile Projects</ListGroup.Item>
                    <ListGroup.Item>3.5.4	Agile Techniques of Business Analysis</ListGroup.Item>
                  </ListGroup>
                </ListGroup.Item>
                <ListGroup.Item>3.6	Scrum Methodology 
                  <ListGroup>
                    <ListGroup.Item>3.6.1	Scrum Team</ListGroup.Item>
                    <ListGroup.Item>3.6.2	Scrum Techniques</ListGroup.Item>
                    <ListGroup.Item>3.6.3	Scrum Artifacts</ListGroup.Item>
                    <ListGroup.Item>3.6.4	Scrum Meetings</ListGroup.Item>
                  </ListGroup>
                </ListGroup.Item>
                <ListGroup.Item>3.7	Project Roles and Competencies</ListGroup.Item>
                <ListGroup.Item>3.8	Requirements Engineering Basics</ListGroup.Item>
                <ListGroup.Item>3.9	Levels of Requirements, Tool and Techniques</ListGroup.Item>
                <ListGroup.Item>3.10 Perspectives, Systems, Processes, and Actors </ListGroup.Item>
              </ListGroup>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="3">
              <span className="icon major style1 h5 fa-plus-circle" >    4.	The Business Analysis Knowledge Areas (Week3, Week 4, Week 5) </span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="3">
              <Card.Body>
              <ListGroup>
                <ListGroup.Item>4.1	Business Analysis Planning and Monitoring</ListGroup.Item>
                <ListGroup.Item>4.2	Elicitation and Collaboration</ListGroup.Item>
                <ListGroup.Item>4.3	Requirements Life Cycle Management</ListGroup.Item>
                <ListGroup.Item>4.4	Strategy Analysis</ListGroup.Item>
                <ListGroup.Item>4.5	Requirements Analysis and Design Definition</ListGroup.Item>
                <ListGroup.Item>4.6	Solution Evaluation</ListGroup.Item>
              </ListGroup>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="4">
              <span className="icon major style1 h5 fa-plus-circle" >    5.	Techniques (Week 6, Week 7) </span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="4">
              <Card.Body>
              <ListGroup>
                <ListGroup.Item>5.1	Elicitation and Collaboration Techniques</ListGroup.Item>
                <ListGroup.Item>5.2	Diagramming and Modeling Techniques</ListGroup.Item>
                <ListGroup.Item>5.3	Root Cause Analysis Techniques</ListGroup.Item>
                <ListGroup.Item>5.4	Acceptance and Evaluation Definition Techniques</ListGroup.Item>
                <ListGroup.Item>5.5	Post-Project Assessment Techniques</ListGroup.Item>
              </ListGroup>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="4">
              <span className="icon major style1 h5 fa-plus-circle" >    6.	Business Analysis – Practical Concepts (Week 8, Week 9) </span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="4">
              <Card.Body>
              <ListGroup>
              <ListGroup.Item>6.1	The Business Requirements Process
                  <ListGroup>
                    <ListGroup.Item>6.1.1	Understanding and discovering stakeholder requirements</ListGroup.Item>
                    <ListGroup.Item>6.1.2	Analyzing stakeholder requirements</ListGroup.Item>
                    <ListGroup.Item>6.1.3	Documenting stakeholder requirements</ListGroup.Item>
                    <ListGroup.Item>6.1.4	Developing the business requirements document</ListGroup.Item>
                  </ListGroup>
                </ListGroup.Item>
                <ListGroup.Item>6.2	Quality, Verification and Validation
                  <ListGroup>
                    <ListGroup.Item>6.2.1	Understanding the Business Analyst`s role in ensuring a quality product</ListGroup.Item>
                    <ListGroup.Item>6.2.2	Understanding the primary difference between verification and validation</ListGroup.Item>
                    <ListGroup.Item>6.2.3	Understanding the definitions of quality</ListGroup.Item>
                    <ListGroup.Item>6.2.4	Determining the Business Analyst`s role in testing</ListGroup.Item>
                    <ListGroup.Item>6.2.5	The structure of a test plan</ListGroup.Item>
                  </ListGroup>
                </ListGroup.Item>
                <ListGroup.Item>6.3	Requirements and Change Management
                  <ListGroup>
                    <ListGroup.Item>6.3.1	The process of managing requirements and the changes to requirements</ListGroup.Item>
                    <ListGroup.Item>6.3.2	Ensuring the use of an appropriate change management process</ListGroup.Item>
                    <ListGroup.Item>6.3.3	Developing and implementing an appropriate requirement tracing capability</ListGroup.Item>
                    <ListGroup.Item>6.3.4	Understanding the methods and tools available for requirements tracing</ListGroup.Item>
                  </ListGroup>
                </ListGroup.Item>
              </ListGroup>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="5">
              <span className="icon major style1 h5 fa-plus-circle" >    7.	Underlying Competencies (Week 10)</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="5">
              <Card.Body>
              <ListGroup>
                <ListGroup.Item>7.1	Analytical Thinking and Problem Solving </ListGroup.Item>
                <ListGroup.Item>7.2	Behavioral Characteristics</ListGroup.Item>
                <ListGroup.Item>7.3	Business Knowledge</ListGroup.Item>
                <ListGroup.Item>7.4	Communication Skills</ListGroup.Item>
                <ListGroup.Item>7.5	Interaction Skills</ListGroup.Item>
                <ListGroup.Item>7.6	Introduction to BPMN 
                  <ListGroup>
                    <ListGroup.Item>7.6.1	BPMN Overview – Components and Object Types</ListGroup.Item>
                    <ListGroup.Item>7.6.2	Participants and Collaboration</ListGroup.Item>
                    <ListGroup.Item>7.6.3	Activities</ListGroup.Item>
                    <ListGroup.Item>7.6.4	Gateways and Branching</ListGroup.Item>
                    <ListGroup.Item>7.6.5	Events</ListGroup.Item>
                    <ListGroup.Item>7.6.6	Adding more information to your BPMN diagrams</ListGroup.Item>
                    <ListGroup.Item>7.6.7	Use cases, Examples and Wrap Up</ListGroup.Item>
                  </ListGroup>
                </ListGroup.Item>
                <ListGroup.Item>7.7	Tools and Technologies
                  <ListGroup>
                    <ListGroup.Item>7.7.1	UML Tools – MS Visio, Star UML</ListGroup.Item>
                    <ListGroup.Item>7.7.2	BPMN Tools – MS Visio, Camunda, Activiti</ListGroup.Item>
                    <ListGroup.Item>7.7.3	BA Tools – JIRA, Confluence, MS Project, SharePoint, Trello (Kanban)</ListGroup.Item>
                    <ListGroup.Item>7.7.4	Software Tools – SQL Basics, SQL Tools </ListGroup.Item>
                  </ListGroup>
                </ListGroup.Item>
              </ListGroup>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="6">
              <span className="icon major style1 h5 fa-plus-circle" >    8.	Perspectives (Week 10) </span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="6">
              <Card.Body>
              <ListGroup>
                <ListGroup.Item>8.1	Business Intelligence</ListGroup.Item>
                <ListGroup.Item>8.2	Information Technology</ListGroup.Item>
                <ListGroup.Item>8.3	Business Architecture</ListGroup.Item>
                <ListGroup.Item>8.4	Business Process Management </ListGroup.Item>
              </ListGroup>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="7">
              <span className="icon major style1 h5 fa-plus-circle" >    9.	A Guide to Success on the Exams (Week 11, Week 12) </span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="7">
              <Card.Body>
              <ListGroup>
                <ListGroup.Item>9.1	Review of Key Topics</ListGroup.Item>
                <ListGroup.Item>9.2	Resources for mock CBAP exams</ListGroup.Item>
                <ListGroup.Item>9.3	Key Tips to Remember for the Interview</ListGroup.Item>
                <ListGroup.Item>9.4	Final Project Presentation and Feedback</ListGroup.Item>
                <ListGroup.Item>9.5	Hints and Tricks</ListGroup.Item>
                <ListGroup.Item>9.6	Mock Interviews</ListGroup.Item>
                <ListGroup.Item>9.7	Your Personal Preparation and Support for Interviews</ListGroup.Item>
              </ListGroup>
              </Card.Body>
            </Accordion.Collapse>
          </Card>         
        </Accordion>
        </article>
      </div>
    </div>
  </Layout>
);

export default BusinessAnalystTrng;
